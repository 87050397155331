  .free_app_section {
    padding-top: 70px;
    position: relative;
  }

  .free_app_section .container {
    max-width: 1370px;
  }

  .free_app_section .container .free_app_inner {
    background-color: var(--bg-blue);
    border-radius: 30px;
    padding: 20px 70px;
    padding-bottom: 50px;
    position: relative;
    z-index: 10;
  }

  .free_app_section .container .free_app_inner .dark_bg {
    overflow: hidden;
  }

  .free_app_section .container .free_app_inner .dark_bg span {
    z-index: 99;
  }

  .free_app_section .container .free_app_inner .row {
    align-items: center;
  }

  .free_app_section .container .free_app_inner .free_text .section_title {
    text-align: left;
  }


  .free_app_section .container .free_app_inner .free_text .section_title h1 {
    margin-bottom: 20px;
  }

  .free_app_section .container .free_app_inner .free_text .section_title h1,
  .free_app_section .container .free_app_inner .free_text .section_title p {
    color: var(--text-white);
  }
  .free_app_section .app_btn{
    background-color: transparent;
  }
  .free_app_section .container .free_app_inner .free_img {
    display: flex;
    align-items: center;
    margin-top: -120px;
  }
  .free_app_section .container .free_app_inner .free_img img:last-child {
    margin-left: -65px;
    height: 617px;
}
.free_app_section .container .free_app_inner .free_img img:first-child{
  height: 519px;
}
  @media screen and (max-width:1200px) {
    .free_app_section .container .free_app_inner .free_img img {transform: scale(.8);}
    .free_app_section .container .free_app_inner .free_img img:last-child {margin-left: -120px;}
    .free_app_section .container .free_app_inner .free_img {margin-top: -180px;}
  }

  @media screen and (max-width:992px) {
    .free_app_section .container .free_app_inner {padding: 20px 15px;}
    .free_app_section .container .free_app_inner .free_img img {transform: scale(.7);}
    .free_app_section .container .free_app_inner .free_img img:last-child {margin-left: -180px;}
    .free_app_section .container .free_app_inner .free_text .app_btn li a {padding: 10px 20px;}
  }

  @media screen and (max-width:767px) {
    .free_app_section .container .free_app_inner .free_text .section_title h1, .section_title h1 {font-size: 24px;}
    .free_app_section .container .free_app_inner {padding: 50px 15px; padding-bottom: 0; z-index: 99;}
    .free_app_section .container .free_app_inner .free_img {margin-top: 0; justify-content: center;}
    .free_app_section .container .free_app_inner .free_img img {max-width: 100%;}
    .free_app_section .container .free_app_inner .free_text .section_title {text-align: center;}
    .free_app_section .container .free_app_inner .free_text .app_btn {width: 100%; justify-content: center;}
    .free_app_section .container .app_btn .row-left img{width: 120px;}
    .free_app_section .container .free_app_inner .free_text .app_btn li a .appstore-badge{width: 100%;}
    .free_app_section .container .free_app_inner .free_text .app_btn li a img{width: 100%;}
    .free_app_section .container .free_app_inner .free_text .app_btn li a {width: 136px; text-align: center; padding: 0;}
    .free_app_section .container .free_app_inner .free_text .app_btn li:last-child {margin-left: 0;}

  }
  @media screen and (max-width:375px) {
    .free_app_section .container .free_app_inner .free_text .app_btn {align-items: inherit;}
  }