  .interface_section .screen_slider {
    margin-top: 35px;
    min-height: 720px;
  }
  

  .interface_section .owl-item .screen_frame_img img {
    transform: scale(.9);
    border: 2px solid #000;
    border-radius: 30px;
    transition: 1s all;
    margin: 0 auto;
  }
  .interface_section .owl-item.center .screen_frame_img img {
    transform: scale(1);
    border: 3px solid #000;
  
  }

  @media screen and (max-width:992px) {
    .interface_section .screen_slider {min-height: 550px;}
  }

  @media screen and (max-width:767px) {

    .interface_section .owl-item .screen_frame_img img {transform: scale(1); height: 500px; width: auto;}

  }