  .faq_section .faq_panel {
    margin-top: 40px;
  }
  
  .faq_section .faq_panel .card {
    border: none;
    margin-bottom: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 10px #EDE9FE;
    padding: 16px 0;
  }
  
  .faq_section .faq_panel .card:last-child {
    margin-bottom: 0;
  }
  
  .faq_section .faq_panel .card-header {
    background-color: transparent;
    border: none;
    padding-bottom: 0;
    padding-top: 0;
  }
  
  .faq_section .faq_panel .card-header .btn {
    padding: 0;
    color: var(--dark-purple);
    font-weight: 600;
    font-size: 20px;
  }
  
  .faq_section .faq_panel .card-header .btn.active {
    color: var(--blue);
  }
  
  .faq_panel .accordion button,
  .faq_panel .accordion button:hover,
  .faq_panel .accordion button:focus {
    text-decoration: none;
  }
  
  .faq_section .faq_panel .card-header .icon_faq {
    position: absolute;
    right: 20px;
    color: #839BC0;
  }
  
  .faq_section .faq_panel .card-header h2 {
    line-height: 1;
  }

  .faq_section .faq_panel .card-body {
    padding-bottom: 0;
  }

  @media screen and (max-width:767px) {
    .faq_section .faq_panel .card-header h2 {text-align: left;}
    .faq_section .faq_panel .card-header .btn {font-size: 16px; padding-right: 30px; text-align: left;}
    .faq_section .faq_panel .card-body p {text-align: left;}
  }