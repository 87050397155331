  .trusted_section {
    margin-top: 40px;
  }
  
  .trusted_section .company_logos {
    padding-top: 20px;
  }
  
  .trusted_section .company_logos img {
    margin: 0 auto;
    height: 81px;
    transition: .4s all;
  }
  
  .trusted_section .company_logos .logo-hd img:hover {
    box-shadow: rgba(226 158 48 / 40%) 0px 7px 29px 0px;
  }

  .trusted_section .company_logos .logo img:hover{
    height: 75px;
  }
  .trusted_section .company_logos .logo-4e img:hover {
    box-shadow: rgba(0 90 226 / 40%) 0px 7px 29px 0px;
  }
  .trusted_section .company_logos .logo-corgi img:hover {
    box-shadow: rgba(250 192 12 / 40%) 0px 7px 29px 0px;
  }
  .trusted_section .company_logos .logo-zens img,
  .trusted_section .company_logos .logo-biscope img{
    width: auto;
    height: 81px;
  }
  .trusted_section .company_logos .logo-biscope img{
    padding: 8px;
  }
  .trusted_section .company_logos .logo-biscope img:hover{
    box-shadow: rgba(3 12 59 / 40%) 0px 7px 29px 0px;
  }
  .trusted_section .company_logos .logo-zens img:hover{
    box-shadow: rgba(66 158 197 / 40%) 0px 7px 29px 0px;
  }

  .trusted_section .container .company_logos .owl-item{
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .trusted_section .owl-carousel .owl-item img{
    border-radius: 15px;
  }
  
  @media screen and (max-width:767px) {

    .trusted_section .company_logos {padding-top: 0;}
    .trusted_section .company_logos img {max-width: 70%;}
  }