  .type-users_section .toggle_block {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .type-users_section .toggle_block span {
    color: var(--dark-purple);
    font-weight: 600;
    display: block;
    margin: 0 5px;
  }
  
  .tog_btn.month_active {
    left: 35px !important;
  }
  
  .type-users_section .toggle_block span.deactive {
    color: var(--body-text-purple);
  }
  
  .type-users_section .toggle_block .offer {
    background-color: var(--bg-white);
    border-radius: 5px;
    padding: 2px 10px;
    font-weight: 400;
    font-size: 13px;
    color: var(--blue);
  }
  
  .type-users_section .toggle_block .tog_block {
    width: 70px;
    height: 35px;
    background-color: var(--bg-white);
    border-radius: 18px;
    margin: 0 10px;
    position: relative;
    cursor: pointer;
  }
  
  .type-users_section .toggle_block .tog_block .tog_btn {
    height: 23px;
    width: 23px;
    border-radius: 25px;
    display: block;
    background-color: var(--blue);
    position: absolute;
    left: 3px;
    top: 50%;
    transform: translateY(-50%);
    transition: .4s all;
  }
  
  .type-users_section .toggle_block .month.active,
  .type-users_section .toggle_block .years.active {
    color: var(--blue);
  }
  

  .type-users_section .type-users_pannel {
    margin-top: 50px;
    display: none;
  }
  
  .type-users_section .type-users_pannel.active {
    display: block;
  }
  
  .type-users_section .type-users_pannel .type-users_block {
    text-align: center;
    background-color: var(--bg-white);
    min-height: 100%;
    border-radius: 12px;
    padding-top: 60px;
    margin-bottom: 40px;
    box-shadow: 0px 4px 30px #EDE9FE;
  }
  .type-users_section .type-users_pannel .type-users_block.highlited_block {
    background-color: var(--blue);
    padding-top: 67px;
  }
  
  .type-users_section .type-users_pannel .type-users_block.highlited_block p,
  .type-users_section .type-users_pannel .type-users_block.highlited_block h3,
  .type-users_section .type-users_pannel .type-users_block.highlited_block span,
  .type-users_section .type-users_pannel .type-users_block.highlited_block .pkg_name span {
    color: var(--text-white);
  }
  
  .type-users_section .type-users_pannel .type-users_block .icon {
    margin-bottom: 35px;
  }
  .type-users_section .type-users_pannel .type-users_block.highlited_block .icon{
    margin-bottom: 30px;
  }

  .type-users_section .type-users_pannel .type-users_block .icon img {
    width: 150px;
  }
  

  .type-users_section .type-users_pannel .type-users_block .pkg_name{
    height: 79px;
  }
  .type-users_section .type-users_pannel .type-users_block .pkg_name h3 {
    font-size: 20px;
    font-weight: 600;
    color: var(--blue);
    margin-bottom: 0;
  }
  .type-users_section .type-users_pannel .type-users_block.highlited_block .pkg_name h3{
    color: #FFF;
  }
  
  .type-users_section .type-users_pannel .type-users_block .pkg_name span {
    color: var(--body-text-purple);
    font-size: 15px;
  }
  
  .type-users_section .type-users_pannel .type-users_block .type-user {
    font-size: 28px;
    color: var(--body-text-purple);
    margin: 25px 0;
    display: block;
    font-weight: 600;
  }
  

  .type-users_section .type-users_pannel .type-users_block .benifits {
    margin-top: 55px;
    margin-bottom: 40px;
    padding: 0 23px;
  }
  .type-users_section .type-users_pannel .type-users_block.highlited_block .benifits{
    margin-top: 50px;
  }
  .type-users_section .type-users_pannel .type-users_block .benifits li{
    padding: 0 10px;
  }
  
  .type-users_section .type-users_pannel .type-users_block .benifits li p {
    margin-bottom: 8px;
    text-align: left;
  }
  
  .type-users_section .contact_text {
    text-align: center;
    margin-bottom: 0;
  }
  
  .type-users_section .contact_text a {
    color: var(--blue);
    text-decoration: underline;
  }

  @media screen and (max-width:992px) {
    .type-users_section .type-users_pannel .type-users_block {padding-left: 15px; padding-right: 15px; min-height: 600px; padding-top: 30px;}
    .type-users_section .type-users_pannel .type-users_block .price {font-size: 36px;}
    .type-users_section .type-users_pannel .type-users_block .benifits li p {font-size: 14px;}
  }

  @media screen and (max-width:992px){
    .type-users_section .type-users_pannel .type-users_block .benifits{padding: 0}
  }

  @media screen and (max-width:767px) {
    .type-users_section .type-users_pannel .type-users_block {min-height: auto; padding: 30px 15px;}
    .type-users_section .type-users_pannel .type-users_block .icon {margin-bottom: 15px;}
    .type-users_section .contact_text {margin-top: -20px;}
    .type-users_section .type-users_pannel .type-users_block .benifits{ 
      margin-top: 25px; 
    }
    .type-users_section .type-users_pannel .type-users_block.highlited_block .benifits{
      margin-top: 25px;
    }
  }