  .outstanding_section .row {
    align-items: center;
  }
  
  .outstanding_section .design_block {
    margin-top: 45px;
  }
  

  .outstanding_section .section_title {
    text-align: left;
  }
  
  .outstanding_section .ui_text {
    padding-right: 75px;
  }
  

  .outstanding_section .design_block li {
    padding-left: 40px;
    position: relative;
    margin-bottom: 25px;
  }
  
  .outstanding_section .design_block li::before {
    content: "";
    position: absolute;
    left: 0;
    top: 5px;
    background-image: url(../../assets/images/right_icon.png);
    width: 22px;
    height: 22px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  
  .outstanding_section .design_block li h4 {
    font-size: 20px;
    color: var(--blue);
    font-weight: 600;
    margin-bottom: 8px;
  }
  
  .outstanding_section .design_block li p {
    margin-bottom: 0;
  }
  

  .outstanding_section .ui_images {
    display: flex;
    position: relative;
  }
  
  .outstanding_section .ui_images::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 570px;
    height: 570px;
    border-radius: 100%;
    background-color: var(--bg-white);
    z-index: -1;
  }
  .outstanding_section .ui_images .left_img img{
    height: 672px;
    width: auto;
  }
  .outstanding_section .ui_images .right_img img:nth-child(3) {
    margin-left: -140px;
    margin-top: -20px;
  }
  
  .outstanding_section .ui_images .right_img img:nth-child(2) {
    margin-left: -90px;
    margin-top: -20px;
    width: auto;
    height: 382px;
  }
  
  .outstanding_section .ui_images .right_img img:nth-child(1) {
    position: relative;
    top: 15px;
    z-index: 99;
    margin-left: -15px;
    width: auto;
    height: 240px;
  }

  @media screen and (max-width:1200px) {
    .outstanding_section .ui_images::before {width: 475px; height: 475px;}
    .outstanding_section .ui_images .right_img img:nth-child(1) {margin-left: -60px;}
    .outstanding_section .ui_images .right_img img:nth-child(2) {margin-left: -140px;}
  }

  @media screen and (max-width:992px) {
    .outstanding_section .ui_images {margin-top: 30px;}
    .outstanding_section .ui_text {padding-right: 0;}
    .outstanding_section .ui_images .right_img {display: flex; flex-direction: column; align-items: center;}
  }

  @media screen and (max-width:767px) {
    .outstanding_section .ui_images {margin-top: 10px;}
    .outstanding_section .section_title {text-align: center;}
    .outstanding_section .design_block li {padding-left: 0; margin-bottom: 30px;}
    .outstanding_section .design_block li::before {position: relative; left: auto; top: auto; display: inline-block;}
  
    .outstanding_section .ui_images img {max-width: 100%;}
    .outstanding_section .ui_images::before {width: 350px; height: 350px;}
    .outstanding_section .ui_images .left_img {width: 70%;}
    .outstanding_section .ui_images .left_img img{height: 400px; width: auto;}
    .outstanding_section .ui_images .right_img {justify-content: center;}
    .outstanding_section .ui_images .right_img img:nth-child(1) {top: 0; width: 180px; margin-left: -98px;}
    .outstanding_section .ui_images .right_img img:nth-child(2){height: 140px; max-width: inherit;}
    .outstanding_section .ui_images .right_img img:nth-child(3) {margin-top: 0;}
  }