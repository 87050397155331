  .newsletter_box {
    background-color: var(--bg-blue);
    padding: 50px;
    border-radius: 12px;
    box-shadow: 0 4px 10px #0c0c0c21;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: -95px;
    position: relative;
    z-index: 99;
  }
  
  .newsletter_box .section_title {
    width: 45%;
  }
  
  .newsletter_box form {
    width: 60%;
  }
  

  .newsletter_box .section_title h1 {
    margin-bottom: 5px;
    letter-spacing: -1px;
  }
  
  .newsletter_box .section_title h1,
  .newsletter_box .section_title p {
    color: var(--text-white);
    text-align: left;
  }
  

  .newsletter_box .section_title p {
    margin-bottom: 0;
  }
  

  .newsletter_box form {
    display: flex;
  }
  
  .newsletter_box form .form-group {
    margin-bottom: 0;
  }
  
  .newsletter_box form .form-group .form-control {
    width: 430px;
    height: 55px;
    border-radius: 6px;
    color: var(--dark-purple);
  }
  
  .newsletter_box form .form-group .form-control::placeholder {
    color: var(--dark-purple);
  }
  
  .newsletter_box .error-alert{
    display: none;
  }
  
  .newsletter_box .error-alert_show{
    display: block;
    color: red;
  }

  .newsletter_box form .form-group .btn {
    height: 55px;
    width: 170px;
    text-transform: uppercase;
    color: var(--blue);
    background-color: var(--bg-white);
    border-radius: 6px;
    margin-left: 10px;
    font-weight: 600;
    position: relative;
    overflow: hidden;
    z-index: 1;
  }
  
  .newsletter_box form .form-group .btn::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 0%;
    height: 100%;
    background-color: var(--bg-blue);
    border-radius: 0;
    transition: .6s all;
    z-index: -1;
  }
  
  .newsletter_box form .form-group .btn:hover::before {
    width: 100%;
  }
  
  .newsletter_box form .form-group .btn:hover {
    color: var(--text-white);
  }

  @media screen and (max-width:1200px) {

    .newsletter_box .section_title {width: 60%;}
    .newsletter_box .section_title h1 {font-size: 24px; letter-spacing: 1px;}
    .newsletter_box form .form-group .form-control {width: 250px;}
  }
  @media screen and (max-width:992px) {

    .newsletter_box {flex-direction: column; padding: 30px 20px;}
    .newsletter_box .section_title , .newsletter_box form {width: 100%; margin: 10px 0; justify-content: center;}
    .newsletter_box form .form-group .form-control {width: 350px;}
    .newsletter_box .section_title h1, .newsletter_box .section_title p {text-align: center;}
  }

  @media screen and (max-width:767px) {

    .newsletter_box form {flex-direction: column; align-items: center;}
    .newsletter_box form .form-group {width: 100%;}
    .newsletter_box form .form-group .form-control {width: 100%; margin-bottom: 15px;}
    .newsletter_box .section_title p {font-size: 13px;}
  }