@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    src: url('../../assets/fonts/poppins-v20-latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../assets/fonts/poppins-v20-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../assets/fonts/poppins-v20-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../assets/fonts/poppins-v20-latin-300.woff') format('woff'), /* Modern Browsers */
         url('../../assets/fonts/poppins-v20-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../assets/fonts/poppins-v20-latin-300.svg#Poppins') format('svg'); /* Legacy iOS */
  }

  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: url('../../assets/fonts/poppins-v20-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../assets/fonts/poppins-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../assets/fonts/poppins-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../assets/fonts/poppins-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../../assets/fonts/poppins-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../assets/fonts/poppins-v20-latin-regular.svg#Poppins') format('svg'); /* Legacy iOS */
  }

  @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    src: url('../../assets/fonts/poppins-v20-latin-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../assets/fonts/poppins-v20-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../assets/fonts/poppins-v20-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../assets/fonts/poppins-v20-latin-italic.woff') format('woff'), /* Modern Browsers */
         url('../../assets/fonts/poppins-v20-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../assets/fonts/poppins-v20-latin-italic.svg#Poppins') format('svg'); /* Legacy iOS */
  }

  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    src: url('../../assets/fonts/poppins-v20-latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../assets/fonts/poppins-v20-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../assets/fonts/poppins-v20-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../assets/fonts/poppins-v20-latin-500.woff') format('woff'), /* Modern Browsers */
         url('../../assets/fonts/poppins-v20-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../assets/fonts/poppins-v20-latin-500.svg#Poppins') format('svg'); /* Legacy iOS */
  }

  @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 500;
    src: url('../../assets/fonts/poppins-v20-latin-500italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../assets/fonts/poppins-v20-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../assets/fonts/poppins-v20-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../assets/fonts/poppins-v20-latin-500italic.woff') format('woff'), /* Modern Browsers */
         url('../../assets/fonts/poppins-v20-latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../assets/fonts/poppins-v20-latin-500italic.svg#Poppins') format('svg'); /* Legacy iOS */
  }

  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    src: url('../../assets/fonts/poppins-v20-latin-600.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../assets/fonts/poppins-v20-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../assets/fonts/poppins-v20-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../assets/fonts/poppins-v20-latin-600.woff') format('woff'), /* Modern Browsers */
         url('../../assets/fonts/poppins-v20-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../assets/fonts/poppins-v20-latin-600.svg#Poppins') format('svg'); /* Legacy iOS */
  }

  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    src: url('../../assets/fonts/poppins-v20-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../assets/fonts/poppins-v20-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../assets/fonts/poppins-v20-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../assets/fonts/poppins-v20-latin-700.woff') format('woff'), /* Modern Browsers */
         url('../../assets/fonts/poppins-v20-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../assets/fonts/poppins-v20-latin-700.svg#Poppins') format('svg'); /* Legacy iOS */
  }

  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 800;
    src: url('../../assets/fonts/poppins-v20-latin-800.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../assets/fonts/poppins-v20-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../assets/fonts/poppins-v20-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../assets/fonts/poppins-v20-latin-800.woff') format('woff'), /* Modern Browsers */
         url('../../assets/fonts/poppins-v20-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../assets/fonts/poppins-v20-latin-800.svg#Poppins') format('svg'); /* Legacy iOS */
  }

  :root {
    --light-blue: #f4f6fe;
    --blue: #045ae2;
    --bg-blue: #045ae2;
    --dark-blue: #1e3760;
    --body-text-blue: #43546e;
    --text-white: #ffffff;
    --bg-white: #ffffff;
    --slider-dots-color: #ccd1d8;
    --light-bg: #ccdcf9;
  }

  html{scroll-behavior:smooth}

  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 1.7;
    font-family: 'Poppins', sans-serif;
    color: var(--body-text-blue);
    background-color: var(--light-blue);
  }

  .page_wrapper {
    width: 100%;
    overflow-x: hidden;
  }

  a {
    text-decoration: none;
    color: var(--body-text-blue);
  }

  a:hover {
    text-decoration: none;
    color: var(--body-text-blue);
  }

  ul,
  li {
    padding: 0;
    list-style-type: none;
    margin: 0;
  }

  button:focus,
  .btn.focus,
  .btn:focus {
    outline: none;
    box-shadow: none;
  }

  @media screen and (min-width:1200px) {
    .container {
        max-width: 1170px;
    }
  }

  .section_title {
    text-align: center;
  }


  .section_title h1 {
    font-size: 40px;
    font-weight: 700;
    color: var(--dark-blue);
  }

  .section_title h1 span {
    color: var(--blue);
  }

  .row_am {
    padding: 50px 0;
  }
  .app_btn {
    width: max-content;
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }

  .app_btn .row-left {
    grid-area: 1 / 1 / 3 / 2;
  }
  .app_btn .row-left img{
    width: 150px;
  }
  .app_btn li:first-child {
    grid-area: 1 / 2 / 2 / 3;
  }

  .app_btn li:last-child {
    grid-area: 2 / 2 / 3 / 3;
  }
  .download-app{
    width: 50%;

  }
   .download-app p{
    font-size: 22px;
  }

  .app_btn li a {
    display: block;
    position: relative;
    border-radius: 12px;
    transition: .4s all;
  }

  .app_btn li a img {
    transition: .4s all;
  }
   .app_btn li a .appstore-badge{
    width: 180px;
  }
   .app_btn .row-left .qr-img{
    height: 100%;
  }

  .puprple_btn {
    background-color: var(--blue);
    color: var(--text-white);
    border-radius: 50px;
    padding: 10px 40px;
    position: relative;
    overflow: hidden;
    z-index: 1;
    font-weight: 500;
  }

  .puprple_btn::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 0%;
    height: 100%;
    background-color: var(--bg-white);
    border-radius: 50px;
    transition: .6s all;
    z-index: -1;
  }

  .puprple_btn:hover::before {
    width: 100%;
  }

  .puprple_btn:hover {
    color: var(--blue);
  }

  .white_btn {
    padding: 10px 45px;
    border: 1px solid var(--blue);
    color: var(--blue);
    border-radius: 50px;
    background-color: var(--bg-white);
    font-weight: 700;
    position: relative;
    z-index: 1;
    overflow: hidden;
    font-weight: 500;
  }

  .white_btn::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 0%;
    height: 100%;
    background-color: var(--bg-blue);
    border-radius: 50px;
    transition: .6s all;
    z-index: -1;
  }

  .white_btn:hover::before {
    width: 110%;
  }

  .white_btn:hover {
    color: var(--text-white);
  }

  .highlited_block .white_btn:hover {
    border-color: var(--bg-white);
  }

  .owl-carousel .owl-dots {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: 20px;
  }

  .owl-carousel .owl-dots span {
    display: block;
    width: 15px;
    height: 15px;
    background-color: var(--slider-dots-color);
    border-radius: 15px;
    margin: 0 5px;
  }

  .owl-carousel .owl-dots .active span{
    background-color: var(--blue);
  }

  #preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 99;
  }

  #loader {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: var(--bg-blue);
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }

  #loader:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: var(--body-text-blue);
    -webkit-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite;
  }

  #loader:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: var(--dark-blue);
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
  }

  @-webkit-keyframes spin {
    0%   {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0%   {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
  }

  .blue_backdrop {
    content: "";
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(35 68 111 / 95%);
    width: 100%;
    height: 100%;
    z-index: 9;
    opacity: 0;
    transition: .4s all;
    pointer-events: none;
  }


  .anim_line {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .anim_line span {
    position: absolute;
    z-index: 99999;
    top: -275px;
    animation: star_down_one 6s infinite linear;
    opacity: 0;
  }

  .anim_line.dark_bg {
    max-width: 1170px;
  }

  .anim_line.dark_bg span {
    transform: rotate(180deg);
  }

  .anim_line span:first-child {
    left: -17%;
    animation-delay: 3s;
  }

  .anim_line span:nth-child(2) {
    left: 0%;
    animation-delay: 5s;
  }

  .anim_line span:nth-child(3) {
    left: 17%;
    animation-delay: 1s;
  }

  .anim_line span:nth-child(4) {
    left: 34%;
    animation-delay: 4s;
  }

  .anim_line span:nth-child(5) {
    left: 51%;
    animation-delay: 7s;
  }

  .anim_line span:nth-child(6) {
    left: 68%;
  }

  .anim_line span:nth-child(7) {
    left: 85%;
    animation-delay: 3s;
  }

  .anim_line span:nth-child(8) {
    left: 99%;
    animation-delay: 2s;
  }

  .anim_line span:nth-child(9) {
    left: 117%;
    animation-delay: 5s;
  }

  @keyframes star_down_one {
    0% {
        opacity: 0;
        top: -250px;
    }

    10% {
        opacity: 1;
    }

    90% {
        opacity: 1;
    }

    100% {
        top: 100%;
        opacity: 0;
    }
  }

  .moving_animation {
    animation: moving_object 6s infinite linear;
  }

  .moving_position_animatin {
    position: relative;
    animation: moving_position_animatin 6s infinite linear;
    z-index: -1;
  }

  .about_app_section .about_img .screen_img img {
    animation-delay: 3s;
  }

  .outstanding_section .ui_images .right_img img:nth-child(3) {
    animation-delay: 3s;
  }

  .outstanding_section .ui_images .right_img img:nth-child(2) {
    animation-delay: 3s;
  }

  .outstanding_section .ui_images .right_img img:nth-child(1) {
    animation-delay: 3s;
  }

  @keyframes moving_object {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-30px);
    }

    100% {
        transform: translateY(0);
    }
  }

  @keyframes moving_position_animatin {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-30px);
    }

    100% {
        transform: translateY(0);
    }
  }

  .waves-block {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
    width: 150px;
    height: 150px;
  }

  .waves {
    width: 150px;
    height: 150px;
    background: rgba(255, 255, 255, 0.3);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    border-radius: 100%;
    z-index: -1;
    -webkit-animation: waves 3s ease-in-out infinite;
    animation: waves 3s ease-in-out infinite;
  }

  .wave-1 {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
  }

  .wave-2 {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
    position: absolute;
    top: 0;
  }

  .wave-3 {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
    position: absolute;
    top: 0;
  }

  @keyframes waves {
    0% {
        -webkit-transform: scale(0.2, 0.2);
        transform: scale(0.2, 0.2);
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    }

    50% {
        opacity: 0.9;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
    }

    100% {
        -webkit-transform: scale(0.9, 0.9);
        transform: scale(0.9, 0.9);
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    }
  }

  @keyframes pulse-blue-small-sm {
  0% {
    box-shadow: 0 0 0 0 rgba(107, 73, 242, 0.7);
  }

  100%,
  30% {
    box-shadow: 0 0 0 12px transparent
  }
  }

  @keyframes pulse-blue-medium-sm {
  0% {
    box-shadow: 0 0 0 0 rgba(107, 73, 242, 0.4);
  }

  100%,
  30% {
    box-shadow: 0 0 0 20px transparent
  }
  }


  @keyframes pulse-white-small-sm {
  0% {
    box-shadow: 0 0 0 0 rgba(253, 253, 253, 0.7);
  }

  100%,
  30% {
    box-shadow: 0 0 0 12px transparent
  }
  }

  @keyframes pulse-white-medium-sm {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }

  100%,
  30% {
    box-shadow: 0 0 0 20px transparent
  }
  }

  /* ------Media-Query-Start--------- */
  @media (min-width: 992px){
    .col-lg-4 {
        flex: 0 0 40.333333%;
        max-width: 46.333333%;
    }
  }
  @media (min-width: 992px){
    .col-lg-3 {
        flex: 0 0 21%;
        max-width: 21%;
    }
  }
@media screen and (max-width:1600px) {

    .why_we_section .why_inner {margin: 0 15px;}
    .youtube-video .modal-dialog  {max-width: 1040px !important;}
}


@media screen and (max-width:1200px) {

    .row_am {padding: 50px 0;}

    .youtube-video .modal-dialog  {max-width: 940px !important;}

}

@media screen and (max-width:767px) {
  #root{
    overflow-x: hidden;
  }
    body {font-size: 14px; text-align: center;}
    .row_am {padding: 30px 0;}
    .section_title p br {display: none;}

    .download-app{margin: 0 auto}
    /* animation line section */
    .anim_line , .anim_line.dark_bg {width: 100%;}

    /* go top button section */
    .go_top {bottom: 30px; z-index: 99999;}

    .blue_backdrop {display: none;}

}

@media screen and (max-width:640px) {
    .download-app{ width: 65%; margin: 0 auto;}

}
@media screen and (max-width:375px) {
  .download-app{ width: 75%; }
}