  .header-top {
    background-color: var(--bg-blue);
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.5rem;
    padding: 4px 20px;
    align-items: center;
    position: relative;
    z-index: 10;
  }
  .header-top.white-header-top{
    background-color: var(--bg-white);
  }
  .header-top h3{
    font-size: 18px;
    font-weight: 700;
    margin: 0;
  }
  .header-top.white-header-top h3{
    color: var(--blue) !important;
  }
  .header-top .blue_btn{
    border-radius: 1.5rem;
    background-color: var(--bg-white);
  }
  .header-top.white-header-top .blue_btn{
    background-color: var(--bg-blue);
  }
  .header-top .blue_btn span{
    font-weight: 700;
    display: flex;
    align-items: center;
    color: var(--blue);
    border-radius: 0.375rem;
    font-size: .875rem;
    line-height: 1.25rem;
  }
  .header-top.white-header-top .blue_btn span{
    color: white;
  }
  header {
      position: relative;
      width: 100%;
      z-index: 12;
      transition: .4s all;
  }

  header.fix_style {
      position: fixed;
      top: 0;
      backdrop-filter: blur(5px);
      background-color: #ffffffb4;
      padding: 15px 0;
      transition: none;
      opacity: 0;
      pointer-events: none;
  }

  header.fixed {
      pointer-events: all;
      opacity: 1;
      transition: .4s all;
  }

  header.fixed .navbar {
      padding: 0;
  }

  .navbar {
      padding-left: 0;
      padding-right: 0;
      padding-top: 35px;
  }

  .navbar-expand-lg .navbar-nav {
      align-items: center;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    cursor: pointer;
    padding: 5px 20px;
    font-weight: 500;
  }

  .navbar-expand-lg .navbar-nav .nav-link:hover {
      color: var(--blue);
  }

  .navbar-expand-lg .navbar-nav .nav-link.dark_btn {
    width: 182px;
      color: var(--text-white);
      background-color: var(--blue);
      font-size: 16px;
      text-align: center;
      padding: 9px 25px;
      border-radius: 25px;
      margin-left: 20px;
      position: relative;
  }


  .navbar-expand-lg .navbar-nav .nav-link.dark_btn::before,
  .navbar-expand-lg .navbar-nav .nav-link.dark_btn::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 42px;
      z-index: -1;
  }

  .navbar-expand-lg .navbar-nav .nav-link.dark_btn::before {
      animation: pulse-blue-medium-sm 3.5s infinite
  }

  .navbar-expand-lg .navbar-nav .nav-link.dark_btn::after {
      animation: pulse-blue-small-sm 3.5s infinite
  }
  .navbar-brand{
    margin-right: 0 !important;
  }
  .navbar-brand img {
      width: 150px;
  }


  .navbar-expand-lg .navbar-nav .has_dropdown {
      display: flex;
      align-items: center;
      position: relative;
      border-radius: 10px 10px 0 0;
      transition: .4s all;
  }

  .navbar-expand-lg .navbar-nav .has_dropdown:hover {
      background-color: var(--bg-white);
      box-shadow: 0px 4px 10px #c5c5c580;
  }

  .navbar-expand-lg .navbar-nav .has_dropdown .drp_btn {
      position: relative;
      right: 15px;
  }

  .navbar-expand-lg .navbar-nav .has_dropdown .sub_menu {
      position: absolute;
      top: 100%;
      background-color: var(--bg-white);
      border-radius: 0 10px 10px 10px;
      min-width: 210px;
      max-width: 230px;
      margin-top: -10px;
      transition: .4s all;
      opacity: 0;
      pointer-events: none;
      box-shadow: 0px 4px 10px #c5c5c580;
      z-index: 999;
  }

  .navbar-expand-lg .navbar-nav .has_dropdown .sub_menu {
      display: none;
  }

  .navbar-expand-lg .navbar-nav .has_dropdown .show {
      display: block !important;
  }

  .navbar-expand-lg .navbar-nav .has_dropdown .sub_menu ul {
      margin-left: 0;
      padding: 10px 20px;
  }

  .navbar-expand-lg .navbar-nav .has_dropdown .sub_menu ul li a {
      font-size: 15px;
      position: relative;
      transition: .4s all;
      line-height: 35px;
      font-weight: 500;
  }

  .navbar-expand-lg .navbar-nav .has_dropdown .sub_menu ul li a::before {
      content: "";
      width: 10px;
      height: 10px;
      display: inline-block;
      border: 2px solid var(--blue);
      border-radius: 10px;
      margin-right: 5px;
      position: absolute;
      left: -10px;
      top: 50%;
      transform: translateY(-50%);
      opacity: 0;
      transition: .4s all;
  }

  .navbar-expand-lg .navbar-nav .has_dropdown .sub_menu ul li a:hover {
      padding-left: 15px;
      color: var(--blue);
  }

  .navbar-expand-lg .navbar-nav .has_dropdown .sub_menu ul li a:hover::before {
      opacity: 1;
      left: 0;
  }

  .navbar-expand-lg .navbar-nav .has_dropdown:hover>a,
  .navbar-expand-lg .navbar-nav .has_dropdown:hover>.drp_btn {
      color: var(--blue);
  }

  .navbar-expand-lg .navbar-nav .has_dropdown:hover .sub_menu {
      opacity: 1;
      pointer-events: all;
      margin-top: -1px;
  }


  .toggle-wrap {
      padding: 10px;
      position: relative;
      cursor: pointer;


      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
  }

  .toggle-bar,
  .toggle-bar::before,
  .toggle-bar::after,
  .toggle-wrap.active .toggle-bar,
  .toggle-wrap.active .toggle-bar::before,
  .toggle-wrap.active .toggle-bar::after {
      -webkit-transition: all .2s ease-in-out;
      -moz-transition: all .2s ease-in-out;
      -o-transition: all .2s ease-in-out;
      transition: all .2s ease-in-out;
  }

  .toggle-bar {
      width: 25px;
      margin: 10px 0;
      position: relative;
      border-top: 4px solid var(--body-text-blue);
      display: block;
  }

  .toggle-bar::before,
  .toggle-bar::after {
      content: "";
      display: block;
      background: var(--body-text-blue);
      height: 4px;
      width: 30px;
      position: absolute;
      top: -12px;
      right: 0px;
      -ms-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      -ms-transform-origin: 13%;
      -webkit-transform-origin: 13%;
      transform-origin: 13%;
  }

  .white_header .toggle-bar,
  .white_header .toggle-bar::before,
  .white_header .toggle-bar::after {
      border-top: 4px solid var(--bg-white);
  }

  .toggle-bar::after {
      top: 4px;
  }

  .toggle-wrap.active .toggle-bar {
      border-top: 6px solid transparent;
  }

  .toggle-wrap.active .toggle-bar::before {
      -ms-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
  }

  .toggle-wrap.active .toggle-bar::after {
      -ms-transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
  }

  /* White Header Css Satart */
  .white_header .navbar {
      padding-top: 0 !important;
      top: 35px;
  }

  .white_header.fix_style .navbar {
      top: 0;
  }

  .white_header .navbar-expand-lg .navbar-nav .nav-link,
  .white_header .navbar-expand-lg .navbar-nav .has_dropdown .drp_btn {
      color: var(--text-white);
  }

  .white_header .navbar-expand-lg .navbar-nav .has_dropdown:hover .drp_btn {
      color: var(--blue);
  }

  .white_header .navbar-expand-lg .navbar-nav .nav-link.dark_btn {
      background-color: var(--bg-white);
      color: var(--blue);
  }

  .white_header .navbar-expand-lg .navbar-nav .nav-link.dark_btn::before {
      animation: pulse-white-medium-sm 3.5s infinite
  }

  .white_header .navbar-expand-lg .navbar-nav .nav-link.dark_btn::after {
      animation: pulse-white-small-sm 3.5s infinite
  }

  header.fix_style.white_header {
      background-color: var(--bg-blue) !important;
  }

  @media screen and (max-width:1200px) {


      .navbar-expand-lg .navbar-nav .nav-link {
          padding: 5px 8px;
      }

      .navbar-expand-lg .navbar-nav .nav-link.dark_btn {
          padding: 9px 30px;
      }

      .navbar-expand-lg .navbar-nav .has_dropdown .drp_btn {
          right: 5px;
      }

      .banner_section .banner_slider .right_icon {
          right: -30px;
      }
  }


  @media screen and (max-width:992px) {

      .navbar-toggler-icon {
          display: flex;
          align-items: center;
          color: var(--body-text-blue);
          justify-content: flex-end;
      }

      .white_header .toggle-bar {
          color: var(--text-white);
      }

      .free_app_section .container .free_app_inner .free_text .section_title h2,
      .section_title h2 {
          font-size: 30px;
      }

      header.fix_style {
          padding: 5px 0;
      }

      .white_header .navbar-collapse {
          position: absolute;
          top: 110%;
          width: 100%;
      }

      .white_header .navbar-expand-lg .navbar-nav .nav-link,
      .white_header .navbar-expand-lg .navbar-nav .has_dropdown .drp_btn {
          color: var(--body-text-blue);
      }

      .white_header .navbar-expand-lg .navbar-nav .nav-link.dark_btn {
          background-color: var(--bg-blue);
          color: var(--text-white);
      }


      .navbar-expand-lg .navbar-nav {
          align-items: flex-start;
      }

      .navbar-toggler {
          padding-right: 0;
          line-height: 1.7;
      }

      .navbar-expand-lg .navbar-nav {
          padding: 20px 15px;
          background-color: var(--bg-white);
          border-radius: 15px;
          box-shadow: 0 4px 10px #EDE9FE;
      }

      .navbar-expand-lg .navbar-nav .nav-link.dark_btn {
          margin-left: 0;
          margin-top: 15px;
      }

      .navbar-expand-lg .navbar-nav .has_dropdown {
          flex-direction: column;
          align-items: flex-start;
          width: 100%;
          box-shadow: none;
      }

      .navbar-expand-lg .navbar-nav .has_dropdown:hover {
          box-shadow: none;
      }

      .navbar-expand-lg .navbar-nav .has_dropdown .drp_btn {
          position: absolute;
          right: 0;
          padding: 0 10px;
      }

      .navbar-expand-lg .navbar-nav .has_dropdown .sub_menu {
          position: relative;
          opacity: 1;
          pointer-events: all;
          top: auto;
          background-color: transparent;
          width: 100%;
          border-bottom: 1px solid #6b49f268;
          border-radius: 0;
          width: 100%;
          min-width: 100%;
          max-width: 100%;
          margin-left: 10px;
          display: none;
          box-shadow: none;
          margin-top: 0;
      }

      .navbar-expand-lg .navbar-nav .has_dropdown .sub_menu ul {
          padding: 0;
          margin-top: 0;
      }

      .navbar-expand-lg .navbar-nav .has_dropdown .sub_menu ul li a {
          padding: 5px 10px;
          display: block;
          padding-left: 0;
      }

      .navbar-expand-lg .navbar-nav .has_dropdown:hover .sub_menu {
          margin-top: 0;
      }
  }

  @media screen and (max-width:767px) {


      .navbar {
          padding-top: 15px;
      }

      .navbar-expand-lg .navbar-nav .has_dropdown .sub_menu ul li a {
          text-align: left;
          line-height: 1.5;
          font-size: 14px;
      }
  }
  @media screen and (max-width:600px){
    .header-top{
        gap: 0.5rem;
    }
    .navbar-brand img{
        width: 120px;
    }
  }