  .features_section .feature_detail {
    background-color: var(--bg-white);
    border-radius: 30px;
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-top: 150px;
    padding-top: 60px;
    padding-bottom: 60px;
    box-shadow: 0px 4px 30px #EDE9FE;
  }
  

  .features_section .feature_detail .feature_img {
    position: absolute;
    left: 50%;
    transform: translateX(-50%) !important;
    top: 30px;
  }
  
  .features_section .feature_detail .feature_img img {
    max-width: 100%;
  }
  .features_section .feature_detail .feature_img_frame{
    z-index: 10;
  }
  .features_section .feature_detail .feature_img_frame img{
    position: relative;
    z-index: 10;
    top: -125px;
  }
  .features_section .feature_detail .feature_img_screen{
    width: 100%;
    height: 100%;
    top: 0;
  }
  .features_section .feature_detail  .feature_img_screen img{
    height: 100%;
    position: relative;
    z-index: 0;
    border-radius: 45px;
  }

  
  .features_section .feature_detail .feature_box {
    max-width: 410px;
  }
  
  .features_section .feature_detail .feature_box .data_block {
    margin-bottom: auto;
  }
  
  .features_section .feature_detail .feature_box .data_block h4 {
    font-size: 20px;
    color: var(--blue);
    font-weight: 600;
  }
  
  .features_section .feature_detail .left_data {
    text-align: right;
    padding-left: 125px;
  }
  
  .features_section .feature_detail .right_data {
    padding-right: 130px;
  }
  
  .features_section .feature_detail .left_data .data_block .icon {
    margin-right: -15px;
  }
  
  .features_section .feature_detail .right_data .data_block .icon {
    margin-left: -15px;
  }
  
  .features_section .container {
    max-width: 1370px;
  }
  @media screen and (max-width:1300px) {
    
    .features_section .feature_detail .feature_box {width: 350px;}
    .features_section .feature_detail .left_data {padding-left: 75px;}
    .features_section .feature_detail .right_data {padding-right: 75px;}
}
@media screen and (max-width:1200px) {

  .features_section .feature_detail .feature_box {width: 300px;}
  .features_section .feature_detail .left_data {padding-left: 15px;}
  .features_section .feature_detail .right_data {padding-right: 15px;}
}
@media screen and (max-width:992px) {

  .features_section .feature_detail .feature_img {width: 275px;}
  .features_section .feature_detail .feature_box .data_block {margin-bottom: 30px;}
  .features_section .feature_detail .feature_box {width: 200px;}
}
@media screen and (max-width:767px) {

  .features_section .feature_detail {flex-direction: column-reverse; padding-top: 30px; margin-top: 35px;}
  .features_section .feature_detail .feature_img {position: relative; top: auto; left: auto; transform: none !important; margin: 0 auto;}
  .features_section .feature_detail .feature_img_screen {position: absolute; left: 50%; top: 0; transform: translateX(-50%) !important;}
  .features_section .feature_detail .feature_box {width: 100%; padding: 0 15px; text-align: center;}
  .features_section .feature_detail .left_data .data_block .icon {margin-right: 0;}
  .features_section .feature_detail .right_data .data_block .icon {margin-left: 0;}
  .features_section .feature_detail .feature_box .data_block {margin-bottom: 0; margin-top: 30px;}
  .features_section .feature_detail .feature_img_frame img {top: 0}
}
@media screen and (max-width:640px) {
  .features_section .feature_detail .feature_box { justify-content: center; margin: 0 auto; }
}