.privacy_policy_section {
    margin-bottom: 40px;
  }
  
  .privacy_policy_bredcrumb.bred_crumb .bred_text {
    margin-top: -30px;
  }
  
  .privacy_policy_section .privacy_policy_inner_pannel {
    background-color: #FFFFFF;
    border-radius: 30px;
    padding: 45px 60px;
    margin-top: -120px;
    position: relative;
    box-shadow: 0 8px 30px #c0bad76b;
  }
  
  .privacy_policy_section .privacy_policy_inner_pannel .review {
    display: flex; 
    justify-content: center;
    margin-bottom: 15px;
  }
  
  .privacy_policy_section .privacy_policy_inner_pannel .review span {
    line-height: 1;
  }
  
  .privacy_policy_section .privacy_policy_inner_pannel .review span:first-child {
    color: var(--blue);
    display: inline-block;
    font-weight: 700;
  }
  
  .privacy_policy_section .privacy_policy_inner_pannel .review span:last-child {
    color: var(--dark-blue);
    display: inline-block;
    margin-left: 15px;
    padding-left: 15px;
    border-left: 1px solid var(--dark-blue);
  }
  
  .privacy_policy_section .privacy_policy_inner_pannel .section_title {
    margin-bottom: 40px;
  }
  
  .privacy_policy_section .privacy_policy_inner_pannel .section_title h2 {
    margin-bottom: 0;
  }
  
  .privacy_policy_section .privacy_policy_inner_pannel img {
    max-width: 100%;
  }
  
  .privacy_policy_section .privacy_policy_inner_pannel .main_img {
    margin-bottom: 30px;
  }
  
  .privacy_policy_section .privacy_policy_inner_pannel .info h3 {
    font-weight: 700;
    color: var(--dark-blue);
    margin-top: 30px;
    font-size: 25px;
    margin-bottom: 15px;
  }
  .privacy_policy_section .privacy_policy_inner_pannel .info p a{
    text-decoration: underline;
    font-weight: 700;
  }
  .privacy_policy_section .privacy_policy_inner_pannel .info p{
    text-align: justify;
  }
  .privacy_policy_section .privacy_policy_inner_pannel .info ul {
    padding-left: 50px;
    padding-top: 10px;
    margin-bottom: 30px;
  }
  
  .privacy_policy_section .privacy_policy_inner_pannel .info ul li p {
    position: relative;
    padding-left: 25px;
    margin-bottom: 0;
  }
  
  .privacy_policy_section .privacy_policy_inner_pannel .info ul li p .icon {
    position: absolute;
    left: 0;
    color: var(--blue);
  }
  
  .privacy_policy_section .privacy_policy_inner_pannel .two_img {
    margin-bottom: 70px;
  }
  
  .privacy_policy_section .privacy_policy_inner_pannel .quote_block {
    background-color: #F6F4FE;
    border-radius: 12px;
    padding: 55px 35px 35px 60px;
    position: relative;
    margin-top: 50px;
    margin-bottom: 35px;
  }
  
  .privacy_policy_section .privacy_policy_inner_pannel .quote_block h2 {
    font-size: 25px;
    line-height: 37px;
  }
  
  .privacy_policy_section .privacy_policy_inner_pannel .quote_block p {
    margin-bottom: 0;
    margin-top: 15px;
  }
  
  .privacy_policy_section .privacy_policy_inner_pannel .quote_block .name {
    color: var(--blue);
    font-weight: 700;
  }
  
  .privacy_policy_section .privacy_policy_inner_pannel .quote_block .q_icon {
    position: absolute;
    top: -20px;
    width: 63px;
    height: 63px;
    background-color: var(--bg-white);
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .privacy_policy_section .privacy_policy_inner_pannel .quote_block .q_icon img {
    width: 35px;
  }

  @media screen and (max-width:1200px) {
    .privacy_policy_section .privacy_policy_inner_pannel {margin-top: -100px; padding: 30px;}
  }

  @media screen and (max-width:992px) {
    .privacy_policy_section .privacy_policy_inner_pannel {margin-top: -100px; padding: 30px;}
    .privacy_policy_section .privacy_policy_inner_pannel .info ul {padding-left: 30px;}
    .privacy_policy_section .privacy_policy_inner_pannel .quote_block {padding: 55px 30px 35px 30px;}
  }

  @media screen and (max-width:767px) {
    .privacy_policy_bredcrumb.bred_crumb .bred_text {margin-top: -10px;}
    .privacy_policy_section .privacy_policy_inner_pannel {margin-top: -70px; padding: 25px 20px;}
    .privacy_policy_section .privacy_policy_inner_pannel .section_title {margin-bottom: 20px;}
    .privacy_policy_section .privacy_policy_inner_pannel .section_title h2 {font-size: 22px;}
    .privacy_policy_section .privacy_policy_inner_pannel .info h3 {font-size: 18px;}
    .privacy_policy_section .privacy_policy_inner_pannel .info ul {text-align: left; padding-left: 0;}
    .privacy_policy_section .privacy_policy_inner_pannel .two_img {margin-bottom: 0;}
    .privacy_policy_section .privacy_policy_inner_pannel .two_img img {margin: 10px 0;}
    .privacy_policy_section .privacy_policy_inner_pannel .quote_block .q_icon {left: 50%; transform: translateX(-50%);}
    .privacy_policy_section .privacy_policy_inner_pannel .quote_block {padding: 55px 20px 35px 20px;}
    .privacy_policy_section .privacy_policy_inner_pannel .quote_block h2 {font-size: 18px; line-height: 1.4;}
  }