  footer {
    position: relative;
  }
  footer ul{
    color: #fff;
  }

  footer .top_footer {
    background-color: #065ae2;
    padding: 180px 0 60px 0;
    position: relative;
    overflow: hidden;
  }

  footer .top_footer.has_bg {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }


  footer .top_footer .logo {
    margin-bottom: 10px;
  }

  footer .top_footer .logo img {
    width: 163px;
  }

  footer .top_footer .abt_side li {
    padding: 0 0 10px 0;
  }
  footer .top_footer .abt_side .email-contact{
    margin-left: 13px;
  }

  footer .top_footer .social_media {
    display: flex;
    margin-top: 20px;
    margin-left: 13px;
  }


  footer .top_footer .social_media li a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 37px;
    height: 37px;
    text-align: center;
    line-height: 37px;
    font-size: 15px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 50px;
    margin-right: 10px;
    transition: .4s all;
  }

  footer .top_footer .social_media li a:hover {
    background-color: var(--bg-white);
    color: var(--blue);
  }
  footer .top_footer .social_media li a:hover .icon-telegram path{
    stroke: var(--blue);
  }
  footer .top_footer .social_media li a:hover .icon-discord path{
    fill: var(--blue);
  }
  footer .top_footer .try_out {
    margin-left: -20px;
  }

  footer .top_footer .anim_line span:first-child {
    left: 5%;
  }

  footer .top_footer .anim_line span:nth-child(2) {
    left: 13%;
  }

  footer .app_btn_footer{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: -8px;
  }

  footer .app_btn_footer li a {
    display: block;
    padding: 0px;
    border: 2px solid var(--blue);
    position: relative;
    border-radius: 12px;
    transition: .4s all;
    width: max-content;
    text-align: center;
  }

  footer .app_btn_footer li:nth-child(1){
    margin-top: 4px;
  }

  footer .app_btn_footer li:nth-child(2){
    margin-top: 12px;
  }

  footer .app_btn_footer li a:hover {
    -webkit-box-shadow: 1px 4px 8px -2px rgba(0,0,0,0.5);
    -moz-box-shadow: 1px 4px 8px -2px rgba(0,0,0,0.5);
    box-shadow: 1px 4px 8px -2px rgba(0,0,0,0.5);
  }


  footer .bottom_footer {
    background-color: var(--bg-blue);
  }


  footer h2,
  footer h3,
  footer p,
  footer a {
    color: var(--text-white);
  }

  footer a:hover {
    color: var(--text-white);
  }
  footer .abt_side {
    margin-top: -15px;
  }

  footer h3 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 45px;
    padding-top: 10px;
  }

  footer .links ul li a {
    cursor: pointer;
    display: block;
    margin-bottom: 10px;
  }


  footer .bottom_footer {
    padding: 20px 0;
  }

  footer .bottom_footer p {
    margin-bottom: 0;
    font-size: 15px;
  }

  footer .bottom_footer .developer_text {
    text-align: right;
  }

  footer .bottom_footer .developer_text a {
    text-decoration: underline;
  }


  .go_top {
    position: fixed;
    right: 30px;
    bottom: 75px;
    cursor: pointer;
    transition: .4s all;
    opacity: 0;
    z-index: 99;
  }

  .go_top:hover {
    bottom: 80px;
  }
  .go_top.show{
    opacity: 1;
  }
  .go_top img{
    width: 64px;
    height: 64px;
  }
  @media screen and (max-width:1600px) {

    footer .top_footer.has_bg {background-repeat: repeat-y; background-position: 0 50px;}
  }

  @media screen and (max-width:992px) {

    footer .top_footer .logo , footer h3 {margin-bottom: 20px;}
    footer .abt_side {margin-bottom: 50px;}
    footer .top_footer .try_out {margin-left: 0;}
    footer .top_footer .col-md-6 {margin-bottom: 15px;}
    footer .top_footer.has_bg {background-position: center;}
  }

  @media screen and (max-width:767px) {
    footer .links , footer .abt_side {margin-bottom: 30px;}
    footer .top_footer .abt_side .email-contact{margin-left: 0;}
    footer .top_footer .social_media {justify-content: center;}
    footer .top_footer .social_media li a {margin: 0 5px;}
    footer .top_footer .try_out {margin-left: 0;}
    footer .app_btn_footer li a {margin: 0 auto;}
    footer .bottom_footer .developer_text {text-align: center; margin-top: 10px;}
    footer .go_top {right: 10px;}
    footer .top_footer .col-md-6 {margin-bottom: 0px;}
    footer .top_footer .list-url {max-width: 50%;}
    footer .app_btn_footer{flex-direction: column; gap: 8px;}
    footer .app_btn_footer li{
      margin-top: 0 !important;
    }
  }

