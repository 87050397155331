.banner_section {
  margin-top: 70px;
  position: relative;
}

.banner_section .container {
  position: relative;
}

.banner_section::after {
  content: "";
  display: block;
  background-image: url(../../assets/images/banner-shape.svg);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 200px;
  background-position: center;
  margin-top: -50px;
}

.banner_section .row {
  align-items: center;
}

.banner_section .banner_text {
  margin-top: -50px;
}

.banner_section .banner_text h1 {
  font-size: 55px;
  color: var(--dark-purple);
  letter-spacing: -1.5px;
  font-weight: 700;
}

.banner_section .banner_text h1 span {
  color: var(--blue);
}

.banner_section .app_btn {
  display: flex;
  align-items: center;
}

.banner_section .app_btn li a {
  display: block;
  padding: 12px 30px;
  background-color: var(--bg-white);
  border: 2px solid var(--blue);
  position: relative;
  border-radius: 12px;
  transition: .4s all;
}

.banner_section .app_btn li:last-child {
  margin-left: 25px;
}

.banner_section .app_btn li a img {
  transition: .4s all;
}

.banner_section .app_btn li a .white_img {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
}

.banner_section .app_btn li a:hover {
  background-color: var(--blue);
}

.banner_section .app_btn li a:hover .blue_img {
  opacity: 0;
}

.banner_section .app_btn li a:hover .white_img {
  opacity: 1;
}

.banner_section .used_app {
  display: flex;
  align-items: center;
  margin-top: 35px;
}

.banner_section .used_app ul {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.banner_section .used_app ul li:not(:first-child) {
  margin-left: -15px;
}

.banner_section .used_app p {
  font-size: 15px;
  line-height: 19px;
  margin-bottom: 0;
}

.banner_section .banner_slider {
  display: flex;
  justify-content: center;
  position: relative;
}

.banner_section .banner_slider .left_icon {
  position: absolute;
  left: 30px;
  bottom: 70px;
  z-index: 1;
}

.banner_section .banner_slider .right_icon {
  position: absolute;
  right: 30px;
  top: 70px;
}

.banner_section .banner_slider .slider_frame {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  width: 315px;
}

.banner_section .banner_slider #frmae_slider::before {
  content: "";
  position: absolute;
  left: 5px;
  top: 5px;
  background-color: #fff;
  width: calc(100% - 10px);
  height: 92%;
}

.banner_section .banner_slider::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 475px;
  height: 475px;
  border-radius: 100%;
  background-color: var(--blue);
  z-index: -5;
}

.banner_section .banner_slider #frmae_slider {
  width: 305px;
  margin: 0 auto;
}
.banner_section .banner_slider .item
.banner_section .owl-dots {
  margin-top: 40px;
}

.owl-carousel .owl-item img {
  max-width: 100%;
  width: auto;
  margin: 0 auto;

}

.banner_section .owl-carousel .owl-item img{
  border-radius: 40px;
}

.banner_section .col-left{
  display: flex;
  gap: 20px;
}
.banner_section .col-left p{
  margin: 0;
}
.banner_section .col-left .coin_image{
  width: 100%;
}
.banner_section .col-left .coin_details{
  color: var(--dark-blue);
  background-color: #FFF;
  padding: 10px;
  border-radius: 8px;
}
.banner_section .col-left .coin_details h3{
  font-size: 16px;
  font-weight: 700;
  color: var(--blue);
}
.banner_section .banner_coin_header .header_text{
  color: var(--blue);
}
.banner_section .col-left .coin_details .coin_details_content{
  font-size: 14px;
}
.banner_section .col-left .coin_description{
  padding: 10px;
  padding-bottom: 22px;
  color: var(--dark-blue);
  background-color: #FFF;
  margin-top: 20px;
  border-radius: 8px;
}
.banner_section .col-left .coin_description h3{
  font-size: 16px;
  font-weight: 700;
  color: var(--blue);
}
.banner_section .col-left .coin_description .description_content p{
  font-size: 16px;
  text-align: left;
}
.banner_section .col-left .coin_value{
  margin-top: auto;
  text-align: left;
}
.banner_section .btn-mint{
  color: var(--dark-blue);
  background: #FFF;
  width: 100%;
  height: 80px;
  border: 1px solid var(--blue);
  font-weight: bold;
  margin-top: 25px;
  border-radius: 8px;

}
@media screen and (max-width:1200px) {
  .banner_section .banner_slider .right_icon {right: -30px;}
  .banner_section .banner_slider .left_icon {left: -30px;}
}

@media screen and (max-width:992px) {

  .banner_section .banner_text h1 {font-size: 40px;}
  .banner_section .banner_slider {margin-top: 50px;}
  .banner_section .banner_slider .left_icon {left: 15px;}
  .banner_section .banner_slider .right_icon {right: 15px;}
}
@media screen and (max-width:767px) {
  .owl-carousel .owl-item img{
    display: unset !important;
  }
  .banner_section {margin-top: 60px;}
  .banner_section .banner_text h1 {font-size: 30px;}
  .banner_section .app_btn , .banner_section .used_app {justify-content: center;}
  .banner_section .app_btn li:last-child {margin-left: 0;}
  .banner_section .app_btn li {margin: 0 15px;}
  .banner_section .app_btn li a {padding: 10px 20px;}
  .banner_section .app_btn li a img {height: 25px;}
  .banner_section .banner_slider .left_icon {display: none;}
  .banner_section .banner_slider .right_icon {display: none;}
  .banner_section .banner_slider::before {width: 350px; height: 350px;}
  .banner_section .banner_coin .coin_details .label_details{text-align: left;}
  .banner_section .banner_coin .coin_details .details_value{text-align: right;}
  .banner_section .btn-mint{height: 60px;}
}
@media screen and (max-width:425px){
  .banner_section .col-left .coin_details .coin_details_content{font-size: 10px;}

}