.terms_conditions_section {
    margin-bottom: 40px;
  }
  
  .terms_conditions_bredcrumb.bred_crumb .bred_text {
    margin-top: -30px;
  }
  
  .terms_conditions_section .terms_conditions_inner_pannel {
    background-color: #FFFFFF;
    border-radius: 30px;
    padding: 45px 60px;
    margin-top: -120px;
    position: relative;
    box-shadow: 0 8px 30px #c0bad76b;
  }
  
  .terms_conditions_section .terms_conditions_inner_pannel .review {
    display: flex; 
    justify-content: center;
    margin-bottom: 15px;
  }
  
  .terms_conditions_section .terms_conditions_inner_pannel .review span {
    line-height: 1;
  }
  
  .terms_conditions_section .terms_conditions_inner_pannel .review span:first-child {
    color: var(--blue);
    display: inline-block;
    font-weight: 700;
  }
  
  .terms_conditions_section .terms_conditions_inner_pannel .review span:last-child {
    color: var(--dark-blue);
    display: inline-block;
    margin-left: 15px;
    padding-left: 15px;
    border-left: 1px solid var(--dark-blue);
  }
  
  .terms_conditions_section .terms_conditions_inner_pannel .section_title {
    margin-bottom: 40px;
  }
  
  .terms_conditions_section .terms_conditions_inner_pannel .section_title h2 {
    margin-bottom: 0;
  }
  
  .terms_conditions_section .terms_conditions_inner_pannel img {
    max-width: 100%;
  }
  
  .terms_conditions_section .terms_conditions_inner_pannel .main_img {
    margin-bottom: 30px;
  }
  .terms_conditions_section .terms_conditions_inner_pannel .main_img img{
    border-radius: 10px;
  }
  .terms_conditions_section .terms_conditions_inner_pannel .info h3 {
    font-weight: 700;
    color: var(--dark-blue);
    margin-top: 30px;
    font-size: 25px;
    margin-bottom: 15px;
  }

  .terms_conditions_section .terms_conditions_inner_pannel .info p{
    text-align: justify;
  }

  .terms_conditions_section .terms_conditions_inner_pannel .info p a{
    text-decoration: underline;
    font-weight: 700;
  }

  .terms_conditions_section .terms_conditions_inner_pannel .info ul {
    padding-left: 50px;
    padding-top: 10px;
    margin-bottom: 30px;
  }
  
  .terms_conditions_section .terms_conditions_inner_pannel .info ul li p {
    position: relative;
    padding-left: 25px;
    margin-bottom: 0;
  }
  
  .terms_conditions_section .terms_conditions_inner_pannel .info ul li p .icon {
    position: absolute;
    left: 0;
    color: var(--blue);
  }
  
  .terms_conditions_section .terms_conditions_inner_pannel .two_img {
    margin-bottom: 70px;
  }
  
  .terms_conditions_section .terms_conditions_inner_pannel .quote_block {
    background-color: #F6F4FE;
    border-radius: 12px;
    padding: 55px 35px 35px 60px;
    position: relative;
    margin-top: 50px;
    margin-bottom: 35px;
  }
  
  .terms_conditions_section .terms_conditions_inner_pannel .quote_block h2 {
    font-size: 25px;
    line-height: 37px;
  }
  
  .terms_conditions_section .terms_conditions_inner_pannel .quote_block p {
    margin-bottom: 0;
    margin-top: 15px;
  }
  
  .terms_conditions_section .terms_conditions_inner_pannel .quote_block .name {
    color: var(--blue);
    font-weight: 700;
  }
  
  .terms_conditions_section .terms_conditions_inner_pannel .quote_block .q_icon {
    position: absolute;
    top: -20px;
    width: 63px;
    height: 63px;
    background-color: var(--bg-white);
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .terms_conditions_section .terms_conditions_inner_pannel .quote_block .q_icon img {
    width: 35px;
  }
  
  .terms_conditions_section .terms_conditions_inner_pannel .terms_conditions_authore {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 0;
    border-top: 1px solid #D6D3E3;
    border-bottom: 1px solid #D6D3E3;
    margin: 35px 0;
  }
  
  .terms_conditions_section .terms_conditions_inner_pannel .terms_conditions_authore .authore_info {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .terms_conditions_section .terms_conditions_inner_pannel .terms_conditions_authore .authore_info .text {
    margin-left: 20px;
  }
  
  .terms_conditions_section .terms_conditions_inner_pannel .terms_conditions_authore .authore_info .text h3 {
    font-size: 20px;
    font-weight: 600;
    color: var(--dark-blue);
    margin-bottom: 5px;
  }
  
  .terms_conditions_section .terms_conditions_inner_pannel .terms_conditions_authore .authore_info .text span {
    font-size: 14px;
  }
  
  .terms_conditions_section .terms_conditions_inner_pannel .terms_conditions_authore .social_media ul {
    display: flex;
  }
  
  /* Social Media link list */
  .terms_conditions_section .terms_conditions_inner_pannel .terms_conditions_authore .social_media ul li a {
    display: block;
    width: 37px;
    height: 37px;
    text-align: center;
    line-height: 37px;
    font-size: 15px;
    border: 1px solid #CCC9D7;
    border-radius: 50px;
    margin-right: 10px;
    transition: .4s all;
  }
  
  .terms_conditions_section .terms_conditions_inner_pannel .terms_conditions_authore .social_media ul li:last-child a {
    margin-right: 0;
  }
  
  .terms_conditions_section .terms_conditions_inner_pannel .terms_conditions_authore .social_media ul li a:hover {
    background-color: var(--bg-blue);
    color: var(--text-white);
  }
  
  .terms_conditions_section .terms_conditions_inner_pannel .terms_conditions_tags ul {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .terms_conditions_section .terms_conditions_inner_pannel .terms_conditions_tags ul .tags p {
    margin-bottom: 0;
    color: var(--dark-blue);
    font-weight: 500;
    margin-right: 5px;
  }
  
  .terms_conditions_section .terms_conditions_inner_pannel .terms_conditions_tags ul li span {
    color: var(--blue);
    font-weight: 500;
  }

  @media screen and (max-width:1200px) {
    .terms_conditions_section .terms_conditions_inner_pannel {margin-top: -100px; padding: 30px;}
  }

  @media screen and (max-width:992px) {
    .terms_conditions_section .terms_conditions_inner_pannel {margin-top: -100px; padding: 30px;}
    .terms_conditions_section .terms_conditions_inner_pannel .info ul {padding-left: 30px;}
    .terms_conditions_section .terms_conditions_inner_pannel .quote_block {padding: 55px 30px 35px 30px;}
  }

  @media screen and (max-width:767px) {
    .terms_conditions_bredcrumb.bred_crumb .bred_text {margin-top: -10px;}
    .terms_conditions_section .terms_conditions_inner_pannel {margin-top: -70px; padding: 25px 20px;}
    .terms_conditions_section .terms_conditions_inner_pannel .section_title {margin-bottom: 20px;}
    .terms_conditions_section .terms_conditions_inner_pannel .section_title h2 {font-size: 22px;}
    .terms_conditions_section .terms_conditions_inner_pannel .info h3 {font-size: 18px;}
    .terms_conditions_section .terms_conditions_inner_pannel .info ul {text-align: left; padding-left: 0;}
    .terms_conditions_section .terms_conditions_inner_pannel .two_img {margin-bottom: 0;}
    .terms_conditions_section .terms_conditions_inner_pannel .two_img img {margin: 10px 0;}
    .terms_conditions_section .terms_conditions_inner_pannel .quote_block .q_icon {left: 50%; transform: translateX(-50%);}
    .terms_conditions_section .terms_conditions_inner_pannel .quote_block {padding: 55px 20px 35px 20px;}
    .terms_conditions_section .terms_conditions_inner_pannel .quote_block h2 {font-size: 18px; line-height: 1.4;}
    .terms_conditions_section .terms_conditions_inner_pannel .terms_conditions_authore {flex-direction: column;}
    .terms_conditions_section .terms_conditions_inner_pannel .terms_conditions_authore .authore_info {margin-bottom: 30px;}
    .terms_conditions_section .terms_conditions_inner_pannel .terms_conditions_authore .authore_info .text h3 {font-size: 18px;}
  }