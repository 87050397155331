  #testimonial_slider {
    max-width: 550px;
    margin: 0 auto;
  }
  
  .testimonial_section .testimonial_block {
    background-image: url(../../assets/images/testimonial_bg.png);
    background-size: cover;
    background-position: center;
    position: relative;
    margin-top: 65px;
  }
  
  .testimonial_section .testimonial_block .testimonial_slide_box {
    text-align: center;
    width: 430px;
    padding: 10px;
    margin: 0 auto;
  }
  
  .testimonial_section .testimonial_block .rating span {
    color: #FC9400;
    font-size: 18px;
  }
  
  .testimonial_section .testimonial_block .testimonial_slide_box .review {
    margin-top: 10px;
    margin-bottom: 30px;
  }
  

  .testimonial_section .testimonial_block .testimonial_slide_box .testimonial_img img {
    margin: 0 auto;
  }
  
  .testimonial_section .testimonial_block .testimonial_slide_box h3 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 10px;
  }
  
  .testimonial_section .testimonial_block .testimonial_slide_box .designation {
    font-size: 15px;
  }
  

  .testimonial_section .total_review {
    text-align: center;
    margin-top: 60px;
  }
  
  .testimonial_section .total_review .rating {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  

  .testimonial_section .total_review .rating p {
    margin-bottom: 0;
    font-weight: 600;
    margin-left: 5px;
    color: var(--dark-purple);
  }
  

  .testimonial_section .total_review h3 {
    font-size: 50px;
    font-weight: 600;
    margin-bottom: 0;
    color: var(--dark-purple);
  }
  
  .testimonial_section .total_review a {
    color: var(--blue);
    font-weight: 700;
  }
  
  .testimonial_section .testimonial_block .avtar_faces {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
    width: 100%;
  }
  
  .testimonial_section .testimonial_block .avtar_faces img {
    max-width: 100%;
  }
  .testimonial_section .testimonial_block .testimonial_slide_box .rating{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media screen and (max-width:992px) {
    #testimonial_slider {max-width: 500px;}
  }

  @media screen and (max-width:767px) {
    .testimonial_section .testimonial_block {margin: 0;}
    .testimonial_section .testimonial_block .avtar_faces {display: none;}
    .testimonial_section .testimonial_block .testimonial_slide_box {width: 100%;}
  }