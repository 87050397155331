  .about_app_section .about_img {
    display: flex;
    align-items: center;
    position: relative;
  }
  

  .about_app_section .about_img img {
    max-width: 100%;
  }
  
  .about_app_section .about_img::before {
    content: "";
    position: absolute;
    left: 38%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    height: 500px;
    background-color: var(--bg-white);
    border-radius: 100%;
    z-index: -1;
  }
  .about_app_section .about_img .frame_img img{
    width: auto;
    height: 617px;
  }
  .about_app_section .about_img .screen_img {
    margin-left: -135px;
    margin-top: 110px;
  }
  .about_app_section .about_img .screen_img img{
    width: auto;
    height: 240px;
  }
  .about_app_section .about_text .section_title {
    text-align: left;
  }
  
  .about_app_section .about_text .section_title h2 {
    margin-bottom: 15px;
  }
  
  .about_app_section .about_text .app_statstic {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-top: 40px;
  }
  
  .about_app_section .about_text .app_statstic li {
    width: 248px;
    background-color: var(--bg-white);
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    border-radius: 12px;
    padding: 15px 10px;
    padding-left: 25px;
    box-shadow: 0px 4px 10px #EDE9FE;
  }
  
  .about_app_section .about_text .app_statstic li .icon {
    margin-right: 9px;
  }
  .about_app_section .about_text .app_statstic li .icon img{
    width: 57px;
    height: 57px;
  }
  .about_app_section .about_text .app_statstic li p {
    margin-bottom: 0;
    line-height: 1;
    color: var(--dark-purple);
  }
  
  .about_app_section .about_text .app_statstic li p:first-child {
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 3px;
  }
  .about_app_section .download-app-greeting {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .about_app_section .download-app-greeting .download-app{
    width: 100%;
  }
  .about_app_section .download-app-greeting .download-app .app_btn{
    margin: 0 auto;
  }
  
  .about_app_section .download-app{
    margin-top: 0;
  }
  .about_app_section .puprple_btn{
    height: max-content;
  }

  @media screen and (max-width:1200px) {
    .about_app_section .about_text .app_statstic li {width: 210px; padding-left: 20px;}
    .about_app_section .about_text .app_statstic li p:first-child {font-size: 30px;}
  }

  @media screen and (max-width:992px) {
    .about_app_section .row {flex-direction: column-reverse;}
    .about_app_section .about_img {justify-content: flex-start; margin-top: 75px; margin-left: 0;}
    .about_app_section .about_img::before {left: 50%;}
    .about_app_section .about_img .screen_img {margin-left: -170px;}
    .about_app_section .about_text .app_statstic {justify-content: flex-start;}
    .about_app_section .about_text .app_statstic li {width: 35%; margin-right: 30px;}
    .about_app_section .about_img::before {left: 35%;}
  }

  @media screen and (max-width:767px) {
    .about_app_section .about_text , .about_app_section .about_text .section_title {text-align: center;}
    .about_app_section .about_text .app_statstic {margin-top: 0; justify-content: space-between;}
    .about_app_section .about_text .app_statstic li {width: 48%; margin-right: 0;}
    .about_app_section .about_text .app_statstic li p:first-child {font-size: 18px;}
    .about_app_section .about_img {margin: 0; margin-top: 50px; justify-content: center;}
    .about_app_section .about_img::before {width: 350px; height: 350px;}
    .about_app_section .about_text .download-app-greeting {
      justify-content: center;
      gap: 15px;
    }
    .about_app_section .about_img .frame_img img{height: 500px;}
    .about_app_section .about_img .screen_img img{height: 205px;}
    .about_app_section .about_text, .about_app_section .about_text .section_title br{
      display: block !important;
    }
  }

  @media screen and (max-width:479px) {
    .about_app_section .about_img::before {left: 49%;}
}