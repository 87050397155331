/* -------------How_It_Works-Section-Css-Start------------------ */

  /* how it works wraper */
  .how_it_works .container {
    max-width: 1370px;
  }

  .how_it_works .how_it_inner {
    background-color: var(--bg-white);
    padding: 70px 0;
    padding-bottom: 250px;
    border-radius: 30px;
    box-shadow: 0px 4px 30px #EDE9FE;
  }

  /* how it works list */
  .how_it_works .step_block ul {
    max-width: 1080px;
    margin: 0 auto;
    padding: 10px;
  }

  .how_it_works .step_block ul li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-bottom: 50px;
  }

  .how_it_works .step_block ul li::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50px;
    transform: translateX(-50%);
    width: 4px;
    height: calc(100% + 100px);
    background-color: var(--light-bg);
  }

  .how_it_works .step_block ul li:first-child::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    width: 14px;
    height: 14px;
    background-color: var(--light-bg);
    border-radius: 15px;
  }

  .how_it_works .step_block ul li:first-child::before {
    top: 0;
  }

  .how_it_works .step_block ul li:last-child::before {
    height: 50%;
    top: 0;
  }

  .how_it_works .step_block ul li .step_text,
  .how_it_works .step_block ul li .step_img {
    width: 360px;
    text-align: right;
  }

  /* how it works image */
  .how_it_works .step_block ul li .step_img img {
    max-width: 100%;
  }

  /* how it works heading h4 */
  .how_it_works .step_block ul li .step_text h4 {
    font-size: 20px;
    font-weight: 600;
    color: var(--blue);
  }

  .how_it_works .step_block ul li .step_text .app_icon {
    margin-bottom: 10px;
  }

  .how_it_works .step_block ul li .step_text .app_icon a {
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: var(--bg-blue);
    color: var(--text-white);
    font-size: 20px;
    text-align: center;
    line-height: 40px;
    margin-right: 8px;
    transition: .4s all;
  }
  .how_it_works .step_block ul li .step_text .app_icon a:last-child{
    margin-right: 0;
  }

  .how_it_works .step_block ul li .step_text span {
    font-weight: 600;
  }

  .how_it_works .step_block ul li .step_text span a {
    color: var(--blue);
    text-decoration: underline;
  }

  .how_it_works .step_block ul li:nth-child(2) {
    flex-direction: row-reverse;
  }

  .how_it_works .step_block ul li:nth-child(2) .step_text,
  .how_it_works .step_block ul li:nth-child(2) .step_img {
    text-align: left;
  }

  /* how it works numbers */
  .how_it_works .step_block ul li .step_number {
    width: 110px;
    height: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  /* how it works numbers heading h3 */
  .how_it_works .step_block ul li .step_number h3 {
    font-size: 30px;
    font-weight: 600;
  }

  /* how it works video  */
  .how_it_works .yt_video {
    max-width: 1170px;
    margin: 0 auto;
    margin-top: -200px;
    position: relative;
    overflow: hidden;
  }

  /* how it works video animation line  */
  .how_it_works .yt_video .anim_line {
    z-index: 999;
  }

  .how_it_works .yt_video .thumbnil {
    position: relative;
  }
  .how_it_works .yt_video .iframe-youtube {
    position: relative;
    width: 100%;
    height: 658px;
  }
  .how_it_works .yt_video .thumbnil .thumbnail-img {
    max-width: 100%;
    filter: brightness(0.25);
  }

  .how_it_works .yt_video .thumbnil .play-button {
    position: absolute;
    left: 50%;
    top: 58%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: var(--text-white);
    font-weight: 600;
    z-index: 9;
    cursor: pointer;
  }

  .how_it_works .yt_video .thumbnil .play-button span {
    display: block;
    font-weight: 700;
    font-size: 30px;
  }

  .how_it_works .yt_video .thumbnil .play_btn {
    border-radius: 100px;
    text-align: center;
    margin: 0 auto;
    line-height: 96px;
    position: relative;
    display: block;
    margin-bottom: 40px;
  }
  .how_it_works .yt_video .thumbnil .play_btn img {
    width: 100px;
    position: relative;
    z-index: 1;
  }

  /* how it works video model   */
  .modal {
    z-index: 99;
  }

  .modal-backdrop.show {
    z-index: 99999;
    opacity: .7;
  }

  .youtube-video .modal-dialog {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    padding: 0 15px;
    height: 100%;
    max-width: 1240px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  #video-container {
    position: relative;
    padding-bottom: 50%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
  }

  iframe#youtubevideo {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  .youtube-video .modal-footer {
    border: none;
    text-align: center;
    display: block;
    padding: 0;
  }

  .youtube-video .modal-content {
    background: none !important;
    border: none;
  }

  #close-video {
    color: #fff;
    font-size: 30px;
  }

  .ReactModal__Content{
    padding: 0 !important;
    overflow: unset !important;
  }
  .ReactModal__Overlay{
    background-color: rgba(0, 0, 0, 0.75) !important;
    z-index: 999;
  }
  .icofont-close-line-circled::before {
    font-size: 30px;
    position: absolute;
    right: -15%;
    top: -20%;
    color: white;
  }

  @media screen and (max-width:992px) {
    /* how it work section */
    .how_it_works .step_block ul li .step_text, .how_it_works .step_block ul li .step_img {width: 280px;}
    .how_it_works .yt_video .thumbnil a {top: 57%;}
    .how_it_works .yt_video .thumbnil a span {font-size: 20px;}
  }

  @media screen and (max-width:767px) {
    /* how it work section */
    .how_it_works .how_it_inner {padding: 50px 15px;}
    .how_it_works .step_block ul {padding-top: 30px;}
    .how_it_works .step_block ul li , .how_it_works .step_block ul li:nth-child(2) {flex-direction: column; padding-left: 30px;}
    .how_it_works .step_block ul li .step_text h4 {font-size: 18px;}
    .how_it_works .step_block ul li::before {left: 0; transform: none; height: calc(100% + 10px); top: 40px;}
    .how_it_works .step_block ul li:first-child::before {height: calc(100% + 50px);}
    .how_it_works .step_block ul li:first-child::after {left: -5px; transform: none; display: none;}
    .how_it_works .step_block ul li .step_text, .how_it_works .step_block ul li .step_img ,
    .how_it_works .step_block ul li:nth-child(2) .step_text, .how_it_works .step_block ul li:nth-child(2) .step_img {text-align: center;}
    .how_it_works .step_block ul li .step_text, .how_it_works .step_block ul li .step_img {width: 100%;}
    .how_it_works .step_block ul li .step_number {position: absolute; top: -5px; left: -23px; width: 50px; height: 50px;}
    .how_it_works .step_block ul li:last-child::before {opacity: 0;}
    .how_it_works .step_block ul li .step_number h3 {font-size: 15px; margin-bottom: 0; margin-top: -2px;}
    .how_it_works .yt_video {margin-top: -50px;}
    .how_it_works .yt_video .thumbnil {height: 300px; border-radius: 15px;}
    .how_it_works .yt_video .iframe-youtube {height: 300px; border-radius: 15px;}
    .how_it_works .yt_video .thumbnil img {height: 100%; object-fit: cover; object-position: center; border-radius: 15px;}
    .how_it_works .yt_video .thumbnil a {width: 100%;}
    .how_it_works .yt_video .thumbnil a .play_btn::after {width: 140px; height: 140px;}
    .how_it_works .yt_video .thumbnil a .play_btn::before {width: 120px; height: 120px;}
    .how_it_works .yt_video .thumbnil a .play_btn img {height: auto;}
  }

  @media screen and (max-width:480px){
    .how_it_works .step_block ul li .step_number img{
      width: 90px;
    }
  }