  .bred_crumb {
    background-image: url(../../assets/images/bread_crumb_bg.png);
    /*background: var(--blue);*/
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 500px;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    margin-top: -100px;
  }
  
  .bred_crumb::after {
    content: "";
    background-image: url(../../assets/images/inner_page_banner_overlay.svg);
    position: absolute;
    bottom: -1px;
    left: 0;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 200px;
    background-position: center;
  }
  
  .bred_crumb .bred_text {
    text-align: center;
    z-index: 1000;
    position: relative;
  }
  
  .bred_crumb .bred_text h1 {
    color: var(--text-white);
    font-size: 55px;
    font-weight: 700;
  }
  
  .bred_crumb .bred_text h1 + p {
    color: var(--text-white);
    margin-top: -5px;
  }
  
  .bred_crumb .bred_text ul {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .bred_crumb .bred_text ul li {
    margin: 0 5px;
  }
  
  .bred_crumb .bred_text ul li a, .bred_crumb .bred_text ul li span {
    color: var(--text-white);
    font-size: 14px;
    transition: .4s all;
  }
  
  .bred_crumb .bred_text ul li a:hover {
    text-decoration: underline;
  }
  
  .bred_crumb .bred_text .search_bar {
    margin-top: 25px;
    position: relative;
    z-index: 1000;
  }
  
  .bred_crumb .bred_text .search_bar form .form-group {
    position: relative;
    max-width: 600px;
    height: 60px;
    margin: 0 auto;
  }
  
  .bred_crumb .bred_text .search_bar form .form-group .form-control {
    width: 100%;
    height: 100%;
    border-radius: 100px;
    border: 1px solid var(--bg-blue);
    font-size: 16px;
    padding-left: 30px;
    color: var(--body-text-blue);
  }
  
  .bred_crumb .bred_text .search_bar form .form-group .form-control::placeholder {
    color: var(--body-text-blue);
  }
  
  .bred_crumb .bred_text .search_bar form .form-group .form-control:focus {
    outline: none;
    box-shadow: none;
    border-color: var(--dark-blue);
  }
  
  .bred_crumb .bred_text .search_bar form .form-group .btn {
    position: absolute;
    right: 2px;
    top: 2px;
    background-color: var(--bg-blue);
    width: 56px;
    height: 56px;
    padding: 0;
    border-radius: 100px;
    text-align: center;
    line-height: 56px;
    font-size: 23px;
    color: var(--text-white);
  }
  
  .bred_crumb .bred_text .search_bar form .form-group .btn:hover {
    background-color: var(--dark-blue);
  }

  @media screen and (max-width:992px) {
    .bred_crumb {min-height: 350px;}
    .bred_crumb .bred_text h1 {font-size: 30px;}
    .bred_crumb::after {background-size: 100%; height: 90px; bottom: -5px;}
    .bred_crumb .bred_text .search_bar form .form-group {height: 50px;}
    .bred_crumb .bred_text .search_bar form .form-group .btn {width: 46px; height: 46px; line-height: 46px;}
  }

  @media screen and (max-width:767px) {
    .bred_crumb {min-height: 330px;}
    .bred_crumb .bred_text h1 {font-size: 24px;}
    .bred_crumb .bred_text .search_bar form .form-group {height: 50px;}
    .bred_crumb .bred_text .search_bar form .form-group .btn {width: 46px; height: 46px; line-height: 46px;}
    .bred_crumb .bred_text {padding: 0 15px; margin-top: 15px;}
    .bred_crumb::after {background-size: cover; height: 100px;} 
  }